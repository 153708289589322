
<template>
  <modal
    :visible="value"
    @onClosed="doClose"
    size="xsmall"
  >
    <span slot="head">{{ $t('service') }}</span>
    <div slot="body">
       <div class="flex-w">
           <el-button class="service-item align-center text-center"
           v-for="(item,i) in serviceItems" :key="i"
           @click="handlePath(item.path)">
                <img :src="item.icon">
                <p>{{item.name}}</p>
           </el-button>
       </div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
export default {
  name: 'C2cBuy',
  components: {Modal},
  props: {
    value: Boolean
  },

  data() {
    return {
    }
  },
  computed: {
    curLang() {
      return localStorage.official_locale||window.configObj.defaultLang||'en-US'
    },
    serviceItems() {
      const arr = []
      const services = window.configObj.customerUrl
        try{
          const serviceArr = JSON.parse(services)
          serviceArr.map(item => {
            arr.push({
              name: item[this.curLang],
              icon: require('@/assets/img/service-chat.png'),
              path: item.url
            })
          })
          if (window.configObj.isShowWhatsapp === '1' && window.configObj.whatsapp) {
            window.configObj.whatsapp.split('\n').map(item => {
              arr.push({
                name: 'WhatsApp',
                icon: require('@/assets/img/service-whatsapp.png'),
                path: `https://wa.me/${item}`
              })
            })
          }
          if (window.configObj.isShowLine === '1' && window.configObj.line){
            arr.push({
              name: 'Line',
              icon: require('@/assets/img/line.png'),
              path: window.configObj.line
            })
          }
        } catch(e) {
          console.log(e)
        }
      return arr
    }
  },
  methods: {
    doClose() {
      this.$emit('input', false)
    },
    handlePath(path) {
      window.open(path, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
    .service-item{
       width: 48%;
       height: 120px;
       padding: 0;
       border: none;
       margin-left: 0;
       img {
           width: 60px;
           margin-bottom: 10px;
       }
    }
</style>